<template>
  <div class="d-flex align-center justify-center full-width full-height">
    <v-progress-circular v-if="loading" size="96" width="8" color="primary" indeterminate />
    <h1 v-else-if="showErrorMessage" class="display-1 grey--text">{{ $t('login_failed') }}.</h1>
  </div>
</template>

<script>
import authService from '@/api/auth-service';

export default {
  name: 'QuickAuth',

  data() {
    return {
      loading: true,
      showErrorMessage: false,
    };
  },

  async created() {
    try {
      const params = {
        user_id: this.$route.params.userId,
        hash: this.$route.params.hash,
      };
      const authRes = await authService.quickLogin(params);
      await this.$store.dispatch('handleAuthData', authRes.data);
      await this.$store.dispatch('getCurrentUser');
      if (!this.$store.getters.currentUser?.id) {
        this.showErrorMessage = true;
      } else {
        await this.$router.replace('/');
      }
    } catch (e) {
      this.showErrorMessage = true;
      throw e;
    }
    this.loading = false;
  },
};
</script>

<style scoped></style>
